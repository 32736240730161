@import "../../styles/mia";

.courseDetailWrapper {
  min-height: 600px;
  @include padding-top(50px);

  .lessonWrap {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap-reverse;
    }
  }
}

.courseDetailTop {
  min-height: 205px;
  position: relative;
  @include margin-bottom(65px);
  @include padding-top(10px);
  @include border-radius(12px);

  &::before {
    content: "";
    background-image: linear-gradient(to right,
        #ffffff 25%,
        rgba(255, 255, 255, 0) 79%);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 1048px;
  }

  .courseDetailInner {
    max-width: 400px;
    position: relative;
  }

  .pageHeading {
    @include font-size(24px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.67px;
    @include margin-bottom(12px);
  }

  .pageUser {
    @include font-size(40px);
    font-weight: 300;
    line-height: 1.15;
  }

  .pageSubHeading {
    @include font-size(12px);
    letter-spacing: 1.5px;
    font-weight: 500;
    color: $gray;
    text-transform: uppercase;
    @include margin-top(12px);
    display: flex;
    align-items: center;

    svg {
      @include margin-right(8px);
      @include font-size(16px);
    }
  }
}
@import "styles/mia";

.otherCourses {
    @include margin-top(130px);

    .sectionHeading {
        @include font-size(24px);
        letter-spacing: -0.67px;
        line-height: 1;
        font-weight: 500;
        @include margin-bottom(45px);
    }
}
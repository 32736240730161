@import "../../styles/mia";

.footerWrapper {
  background: $footer-color;
  color: $white;
  @include padding(50px 0 85px);
  @include margin-top(80px);

  .footerBottom {
    .socialIcons {
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;

      @include media-breakpoint-down(sm) {
        justify-content: flex-start;
      }

      div {
        @include margin-left(15px);
        @include font-size(12px);

        @include media-breakpoint-down(sm) {
          @include margin-left(0px);
          @include margin-top(10px);
          @include margin-right(15px);
        }
      }

      a {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $social_background_color;
        color: $white;

        &:hover {
          background: $white;
          color: $social_background_color;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        li {
          @include margin-top(10px);
        }
      }

      a {
        color: $white;
        display: block;
        line-height: 32px;
        letter-spacing: -0.44px;

        &:hover {
          color: $branding1;
        }
      }
    }
  }
}

.footerHeading {
  @include font-size(36px);
  font-weight: 700;
}

.subHeading {
  @include font-size(32px);
  letter-spacing: -0.89px;
  font-family: $opensans;
}

.footerTop {
  @include padding-bottom(40px);
  @include margin-bottom(45px);
  position: relative;
  align-items: flex-end;

  &:after {
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    left: 15px;
    bottom: 0;
    height: 1px;
    background: $gray;
  }
}

.footerLogo {
  max-width: $footer-logo-width;
  height: auto;
  @include margin-bottom(20px);
}

.disclaimer {
  font-size: 11px;
  color: #B5B5B5;
  font-family: $graphik;
  @include margin-top(15px);
}

.homeFooter {
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #ffffff;
  background-size: 100% auto;
  position: relative;
  z-index: 1;
  @include padding(370px 0 80px);
  @include margin-top(-290px);

  &:after {
    z-index: -1;
    content: '';
    height: 30%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $footer-color;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(xxl) {
    //background: $footer-color;
    @include padding(450px 0 80px);
    @include margin-top(-370px);
  }

  @include media-breakpoint-up(xxxl) {
    //background: $footer-color;
    @include padding(500px 0 80px);
    @include margin-top(-450px);
  }


  @include media-breakpoint-up(xxxxl) {
    //background: $footer-color;
    @include padding(600px 0 80px);
    @include margin-top(-550px);
  }

  @include media-breakpoint-down(lg) {
    @include padding(60px 0);
    @include margin-top(0);

    &:before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $footer-color;
    }
  }
}
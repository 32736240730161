@import 'styles/mia';


.resultSection {

    .sectionHeading {
        @include font-size(24px);
        letter-spacing: -0.67px;
        @include margin-bottom(20px);
        font-weight: 300;
        line-height: 1;
        color: $black;
    }

    .resultBtnWrapper {
        button, a {
            width: 100%;
            margin-top: 50px;
        }
    }

    .questionCount {
        text-transform: uppercase;
        @include font-size(11px);
        @include margin-bottom(25px);
        font-weight: 300;
        letter-spacing: 1.22px;
        color: $xs-dark-gray;
        line-height: 1;
        margin-top: 40px;
    }

    .resultWrapper {
        text-align: center;
        @include margin-bottom(80px);

        .imgWrap {
            @include margin-bottom(25px);
        }

        .resultDes {
            .resultDesHead {
                @include font-size(32px);
                color: $black;
                letter-spacing: -0.89px;
                line-height: 1.44;
            }

            .resultDesSubHead {
                @include font-size(36px);
                font-weight: 700;
                letter-spacing: -1px;
                color: $branding1;

                &.errorSubHead {
                    color: $light-red;
                }
            }
        }
    }
}
// src\styles\base\_global.scss

@import '../vendors/rfs';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;

  body {
    min-height: 100%;
    font-family: $graphik;
    font-weight: normal;

    @include font-size(16px);

    a {
      transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out;
    }

    .container {
      max-width: 1230px;
    }
  }
}

.innerpageContent {
  @include margin-bottom(350px);
}

.completeCourse {
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba($white, .6);
  }

  &:after {
    content: '';
    background: url(../../assets/svgs/check-green.svg);
    background-size: contain;
    width: 28px;
    height: 28px;
    position: absolute;
    bottom: 22px;
    z-index: 3;
    right: 22px;
  }
}

.loader {
  margin: 40px auto;
  width:50px;
  height:50px;
  display:inline-block;
  padding:0px;
  text-align:left;
}

.loader span {
  position:absolute;
  display:inline-block;
  width:100px;
  height:100px;
  border-radius:100%;
  background:#075d9f;
  -webkit-animation:loader 1.5s linear infinite;
  animation:loader 1.5s linear infinite;
}

.loader span:last-child {
  animation-delay:-0.9s;
  -webkit-animation-delay:-0.9s;
}

@keyframes loader {
  0% {transform: scale(0, 0);opacity:0.8;}
  100% {transform: scale(1, 1);opacity:0;}
}

@-webkit-keyframes loader {
  0% {-webkit-transform: scale(0, 0);opacity:0.8;}
  100% {-webkit-transform: scale(1, 1);opacity:0;}
}
@import '../../styles/mia';

.dashboardWrapper {
    min-height: 600px;
    @include padding-top(50px);
    .quizPageWrapper {
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap-reverse;
        }
    }
}

.dashboardTop {
    position: relative;
    @include margin-bottom(65px);
    @include padding-top(50px);
    @include padding-bottom(11px);
    background: url(../../assets/imgs/dashboard/dashboard-top.png) no-repeat center right;

    @include media-breakpoint-down(md) {
        background-position: center left;
    }

    .dashboardImage {
        width: 284px;
        height: 225px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        right: 26px;
        top: -30px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .pageHeading {
        @include font-size(24px);
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: -0.67px;
    }

    .pageUser {
        @include font-size(40px);
        text-transform: capitalize;
        font-weight: 300;
        line-height: 1.3;
        max-width: calc(100% - 290px);
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }

    .userEmail {
        @include font-size(12px);
        letter-spacing: 1.5px;
        font-weight: 500;
        color: $gray;
        text-transform: uppercase;
    }
}

.sectionHeading {
    @include font-size(24px);
    @include padding-left(10px);
    font-weight: 500;
    line-height: 1;
    @include margin-bottom(30px);
    position: relative;

    &:after {
        content: '';
        width: calc(100% - 200px);
        height: 1px;
        background: lighten($medium-gray, 15%);
        position: absolute;
        right: 0;
        top: 50%;
    }
}
@import 'styles/mia';

.listWrapper {
    background: $white;
    border: 1px solid $light-gray;
    @include border-radius(4px);
    min-height: 100%;
    position: relative;
    padding-bottom: 30px;
    cursor: pointer;
    position: relative;

    &.noLink {
        cursor: default;
    }

    @include media-breakpoint-down(sm) {
        @include margin-bottom(25px);
    }

    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 146px;
        min-height: 146px;
        overflow: hidden;
        background-color: $branding2;

        svg {
            width: 42px;
            height: auto;
        }
    }

    .courseDetails {
        @include padding(15px 20px);

        a {
            position: absolute;
            bottom: 20px;
        }
    }

    .courseCat {
        @include font-size(14px);
        min-height: 14px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -0.39px;
        @include margin-bottom(8px);

        .green {
            color: $branding1;
        }

        .purple {
            color: $branding1;
        }

        .blue {
            color: $branding1;
        }
    }

    .courseTitle {
        @include font-size(24px);
        line-height: 26px;
        color: $black;
        @include margin-bottom(12px);
        letter-spacing: -0.67px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            max-width: 200px;
        }
    }

    .courseDescription {
        @include font-size(14px);
        font-family: $opensans;
        line-height: 16px;
        color: $xs-dark-gray;
        letter-spacing: -0.39px;
        @include margin-bottom(20px);
    }

    .memberCount {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        bottom: 20px;

        svg.users {
            color: $medium-gray;
        }

        .text {
            @include font-size(12px);
            font-family: $opensans;
            color: $xs-dark-gray;
            font-weight: 600;
            @include margin-left(5px);

            span {
                font-weight: 700;
            }
        }
    }
}
@import 'styles/mia';

:global {
  .modal {
    .modal-content {
      @include padding(0);
    }

    .modal-header {
      padding: 20px;
      @include margin-bottom(18px);

      .modal-title.h4 {
        @include font-size(28px);
        line-height: 1;
        font-weight: 500;
      }
    }

    .modal-footer {
      @include margin-top(0px);
      border: none;
      padding: 20px;

      button {
        height: 45px;
        margin: 10px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
    }

    .modal-body {
      padding: 20px;
    }
  }
}

.proccessPayment {
  text-align: center;

  .imgWrapper {
    @include margin-bottom(20px);
  }

  .paymentHeading {
    @include font-size(28px);
    @include margin-bottom(20px);
    line-height: 1;
    font-weight: 500;
  }

  .paymentSubHeading {
    @include margin-bottom(30px);
    line-height: 1;
  }

  .btnWrapper {
    button {
      min-width: 203px;
      text-align: center;
      height: 45px;
      margin: 0;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }
  }
}
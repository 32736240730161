@import 'styles/mia';

.carousel {
    @include media-breakpoint-down(lg) {
        @include padding(0px 0 100px)
    }

    .carousel-control-next,
    .carousel-control-prev {
        display: none;
    }

    .carousel-indicators {
        width: auto;
        margin: 0;
        left: auto;
        right: auto;
        top: auto;
        list-style: none;
        bottom: 160px;

        @include media-breakpoint-down(lg) {
            bottom: 30px;
        }

        li {
            height: 20px;
            @include margin(0 10px 0 0);
            width: 20px;
            @include border-radius(50%);
            background: $medium-gray;
            @include padding(0);
            box-sizing: border-box;

            &.active {
                background: $branding1;
            }
        }
    }
}
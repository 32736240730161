@import 'styles/mia';


:global {
  .modal {
    .modal-content {
      @include padding(60px 90px);
    }

    .modal-header {
      border: none;
      padding: 0;
      @include margin-bottom(18px);

      .modal-title.h4 {
        @include font-size(28px);
        line-height: 1;
        font-weight: 500;
      }
    }

    .modal-footer {
      @include margin-top(30px);
      border: none;
      padding: 0;

      button {
        height: 45px;
        margin: 0;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}

.sectionHeading {
  @include font-size(16px);
  letter-spacing: -0.44px;
  font-weight: 500;
  @include margin-bottom(20px);
  @include margin-top(30px);
  &.appliedCoupon {
    @include margin-bottom(0px);
    @include margin-top(0px);
  }
}

.paymentDetails {
  .courseDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include padding(22px 0);
    line-height: 1;
    border-bottom: 1px solid $xslight-gray;

    .coursePrice {
      @include font-size(14px);
      font-weight: 500;
    }

    .courseName {
      @include font-size(16px);
      letter-spacing: -0.44px;
    }
  }

  .priceDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include padding(22px 0);
    line-height: 1;

    .coursePrice {
      @include font-size(16px);
      font-weight: 500;
    }

    .courseName {
      @include font-size(16px);
      letter-spacing: -0.44px;
    }
  }
}

.couponCode {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  button {
    position: absolute;
    right: 12px;
    top: 7px;
  }
  .error {
    color: $light-red;
    font-size: 14px;
    margin-top: 5px;
    float: right;
  }
}
@import '../../../../styles/mia';

.partnerWapper {
  @include padding-bottom(40px);

  @include margin-bottom(80px);

  @include media-breakpoint-down(sm) {
    @include padding-bottom(0);
  }

  .sectionHeading {
    @include font-size(14px);
    @include margin-bottom(25px);
    font-weight: 500;
    letter-spacing: 1.75px;
    text-align: center;
    color: $medium-gray;
    text-transform: uppercase;
  }

  .partnerImage {
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    img {
      max-height: 120px;
      max-width: 150px;
      width: auto;
      height: auto;
      @include margin(0 60px);

      @include media-breakpoint-down(sm) {
        @include margin(10px 0);
      }
    }
  }
}
@import '../../../../styles/mia';

.sectionHeading {
    @include font-size(52px);
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1.44px;
    color: $dark-gray;
    @include margin-bottom(20px);
}

.sectionBanner {
    width: 850px;
    height: auto;
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 0;

    @include media-breakpoint-down(lg) {
        width: 650px;
        bottom: 90px;
    }

    @include media-breakpoint-down(md) {
        width: 350px;
        bottom: 140px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 480px;
        position: static;
        @include margin(0 auto);
        display: block;
    }
}

.deepDiveWapper {
    max-width: 360px;
    @include padding-bottom(100px);
    @include padding-top(220px);

    @include media-breakpoint-down(md) {
        @include padding-bottom(80px);
        @include padding-top(80px);
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        @include margin(0 -15px);
    }

    p {
        @include font-size(16px);
        font-weight: 300;
        color: $dark-gray;
        letter-spacing: 0.44px;
        font-family: $opensans;
        line-height: 23px;
    }

    .bannerButtons {
        button {
            @include font-size(18px);
        }
    }
}
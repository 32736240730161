.loader {
  margin: 40px auto;
  width:50px;
  height:50px;
  display:inline-block;
  padding:0px;
  text-align:left;
}

.loader span {
  position:absolute;
  display:inline-block;
  width:100px;
  height:100px;
  border-radius:100%;
  background:#075d9f;
  -webkit-animation:loader 1.5s linear infinite;
  animation:loader 1.5s linear infinite;
}

.loader span:last-child {
  animation-delay:-0.9s;
  -webkit-animation-delay:-0.9s;
}

@keyframes loader {
  0% {transform: scale(0, 0);opacity:0.8;}
  100% {transform: scale(1, 1);opacity:0;}
}

@-webkit-keyframes loader {
  0% {-webkit-transform: scale(0, 0);opacity:0.8;}
  100% {-webkit-transform: scale(1, 1);opacity:0;}
}
// src\styles\abstracts\_variables.scss

:root {
    --branding1: #ef5139;
    --branding1_1: #ffeeec;
    --branding2: #ef5139;
    --branding3: #9143fe;
    --textColor: #1c1c1c;
    --footerColor: #2a323d;
    --headingColor: #1c1c1c;
    --imageBackgroundColor: #f1f1f1;
    --socialBackgroundColor: #ef5139;
    --footerLogoWidth: 155px;
    --inputActiveBorderColor: #ef533b;
    --buttonHoverColor: #e32e12;
}

$branding1: var(--branding1) !default;
$branding1-1: var(--branding1_1) !default;
$branding2: var(--branding2) !default;
$branding3: var(--branding3) !default;
$branding-lighten-05: var(--inputActiveBorderColor) !default;
$branding-darken-10: var(--buttonHoverColor) !default;

$dark-gray : var(--textColor) !default;

$footer-color: var(--footerColor) !default;
$heading-color: var(--headingColor) !default;
$image-background-color: var(--imageBackgroundColor) !default;
$social-background-color: var(--socialBackgroundColor) !default;
$footer-logo-width: var(--footerLogoWidth) !default;

$white: #fff !default;
$black: #000 !default;
$red: #ffeff0 !default;
$dark-red: #ff4146 !default;
// $dark-gray : #1c1c1c !default;
$xs-dark-gray : #5a5a5a !default;
$light-gray : #eeeeee !default;
$xslight-gray : #d1d1d1 !default;
$medium-gray : #bcbcbc !default;
$gray : #979797 !default;
$light-red : #f15f28 !default;

$graphik: 'Graphik',
sans-serif;
$opensans: 'Open Sans',
sans-serif;
// src\components\Form\Input\Input.module.scss

@import '../../../styles/mia';

input {
    &.miaInput {
        margin: 0;
        min-height: 50px;
        border-radius: 0;
        color: $dark-gray;

        &:hover {
            border-color: $branding1;
        }

        &:focus,
        &:active {
            // border-color: lighten($branding1, 0.5%);
            border-color: $branding-lighten-05;
            box-shadow: 0 0 0 0 transparent;
        }

        &::placeholder {
            color: $gray;
        }
    }

    .error {
        border-color: lighten($red, 0.5%);
    }
}
@import "../../../../styles/mia";

.lessonsItem {
    @include margin-bottom(30px);

    :global {
        .customRadio {
            .completed {
                >div {
                    color: lighten($xs-dark-gray, 50%);
                }
            }
        }
    }
}

.lessonDetails {
    color: $xs-dark-gray;
    @include padding-left(15px);
    @include margin-bottom(30px);

    &.active {
        color: $branding2;
    }

    .lessonNo {
        @include font-size(10px);
        letter-spacing: 1.67px;
        text-transform: uppercase;
    }

    .heading {
        letter-spacing: 0.5px;
        line-height: 1.1;
        font-weight: 500;
        @include font-size(22px);
        max-width: 150px;
        @include margin-bottom(5px);
        @include margin-top(5px);
    }

    .duration {
        letter-spacing: 1px;
        line-height: 1.1;
        font-weight: 500;
        @include font-size(12px);
    }
}
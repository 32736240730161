// src\styles\abstracts\_fonts.scss
@font-face {
  font-family: 'Graphik';
  src: url('./fonts/Graphik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./fonts/Graphik-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./fonts/Graphik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./fonts/Graphik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-SemiboldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-ExtraboldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSansLight-Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Extrabold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}
@import 'styles/mia';

.userCards {
	position: relative;
	margin: 0 auto;
	max-width: 100%;
	text-align: center;

	.cardWrapper {
		@include margin-top(20px);

		:global {
			.row {
				align-items: center;
				margin-left: -30px;
				margin-right: -30px;
			}

			.customRadio {
				div {
					label {
						@include font-size(16px);
					}
				}
			}
		}
	}

	.deleteBtn {
		text-align: right;

		@include media-breakpoint-down(sm) {
			@include margin-top(10px);
		}
	}
}
@import 'styles/mia';

.sectionHeading {
    @include font-size(24px);
    @include padding-left(10px);
    font-weight: 500;
    line-height: 1;
    @include margin-bottom(30px);
    position: relative;

    &:after {
        content: '';
        width: calc(100% - 200px);
        height: 1px;
        background: lighten($medium-gray, 15%);
        position: absolute;
        right: 0;
        top: 50%;
    }
}

.topCoursesWapper {
    overflow: hidden;
    @include padding-bottom(80px);

    nav.tabsWrapper {
        border: none;
        @include margin-bottom(46px);
        @include padding-right(100px);

        @include media-breakpoint-down(md) {
            @include padding-right(0);
            @include padding-top(56px);
        }

        a {
            @include border-radius(30px);
            @include font-size(16px);
            @include padding(0 30px);
            font-weight: 500;
            line-height: 36px;
            border: 1px solid $medium-gray;
            color: $xs-dark-gray;
            outline: 0;
            box-shadow: none;
            @include margin(0 10px 10px 0);

            &:hover,
            &[aria-selected="true"] {
                border: 1px solid $branding1;
                color: $branding1;
            }

        }
    }
}

.tabsParent {
    position: relative;
    min-height: 200px;

    .resultsCount {
        position: absolute;
        top: 0;
        right: 0;
        color: $xs-dark-gray;
        font-weight: 500;

        @include media-breakpoint-down(md) {
            right: auto;
            left: 0;
            font-weight: 500;
        }
    }
}

.filterSection {
    @include margin-bottom(46px);
    @include padding-right(100px);

    @include media-breakpoint-down(md) {
        @include padding-right(0);
        @include padding-top(56px);
    }

    .filterLabel {
        color: $xs-dark-gray;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
            @include margin-bottom(10px);
        }
    }

    select {
        min-height: 38px;
        @include border-radius(5px);
        font-weight: 500;
        min-width: 224px;
        color: $branding1;
    }

    :global {
        .row {
            .col-sm-auto:last-child {
                >div {
                    &:after {
                        top: 6px;
                    }
                }
            }
        }
    }
}

.tabContent {
    position: relative;

    &:before {
        content: '';
        width: 100000px;
        position: absolute;
        height: calc(100% - 200px);
        left: -100%;
        top: 96px;
        background: lighten($black, 96.5%);
    }
}

.tabRow {
    >div {
        @include margin-bottom(20px);
    }
}

.btnOuter {
    @include margin-top (40px);
}
@import '../../../../styles/mia';

.mainBanner {

    // @include padding(170px 0 115px);
    @include margin(60px 0);

    .bannerInner {
        .bannerInnerWrapper {
            background-repeat: no-repeat;
            background-position: right;
            background-origin: center;
            min-height: 383px;
            @include padding-top(35px);

            @include media-breakpoint-down(md) {
                align-items: flex-end;
                @include padding(0 45px 20px);
                display: flex;
                justify-content: center;
            }
            @include media-breakpoint-down(sm) {
                background-position: top center;
            }
        }

    }

    .contentContainer {
        max-width: 591px;
        background: url(../../../../assets/svgs/home-banner-overlay.svg) no-repeat right center;
        @include padding(40px 40px 0px 20px);
        min-height: 292px;

        @include media-breakpoint-down(md) {
            background: $white;
            @include padding(20px 15px);
            max-width: 100%;
            min-height: 0;
            text-align: center;
            @include border-radius(10px);
        }
    }

    p {
        @include font-size (18px);
        letter-spacing: 0.2px;
        color: $medium-gray;
        margin: 0;
        font-family: $graphik;
        font-weight: 500;
    }

    h1 {
        @include font-size (58px);
        letter-spacing: -1.62px;
        line-height: 1;
        font-weight: bold;
        @include margin(0 0 10px);
        color: $heading-color;
    }

    .subHeading {
        @include font-size (24px);
        letter-spacing: -0.89px;
        line-height: 1.25;
        @include margin(0 0 20px);
        font-family: $opensans;
        color: $xs-dark-gray;
    }

    .bannerButtons {
        @include media-breakpoint-down(sm) {
            button {
                margin-bottom: 15px;
            }
        }
    }
}
@import '../../styles/mia';

.supportPage {
  @include padding(50px 15px 0);
  max-width: 560px;
  margin: 0 auto;

  :global {
    ._form-title {
      font-size: 24px !important;
      font-weight: 500 !important;
      letter-spacing: -0.67px !important;
      margin-bottom: 20px !important;
    }

    ._html-code {
      font-size: 18px !important;
      font-weight: 300 !important;
      line-height: 18px  !important;
      padding-bottom: 12px !important;
      margin-bottom: 30px !important;
      border-bottom: 1px solid $medium-gray !important;
    }

    ._form-label {
      font-size: 14px !important;
      margin-bottom: 5px !important;
      font-family: $graphik !important;
      font-weight: bold !important;
      letter-spacing: -0.44px !important;
      color: $black !important;
    }

    ._field-wrapper {
      margin-bottom: 24px !important;

      textarea {
        width: 100% !important;;
        resize: none !important;;
        border: 1px solid $xslight-gray !important;
        height: 80px !important;

        &:focus {
          border-color: $branding1 !important;
        }
      }

      select, input {
        height: 35px;
        border: 1px solid #d1d1d1 !important;
      }

      .errorInput {
        border-color: $red !important;
      }
    }

    ._button-wrapper button._submit#_form_158_submit {
      background-color: #2fa0f6 !important;
      border-color: transparent !important;
      color: #fff !important;
      background-image: none !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  .heading {
    @include font-size(24px);
    font-weight: 500;
    letter-spacing: -0.67px;
    @include margin-bottom(20px);
  }

  .sectionHeading {
    @include font-size(18px);
    font-weight: 300;
    line-height: 18px;
    @include padding-bottom(12px);
    @include margin-bottom(30px);
    border-bottom: 1px solid $medium-gray;
  }
}

.authLabel {
  @include font-size(14px);
  @include margin-bottom(5px);

  font-family: $graphik;
  font-weight: bold;
  letter-spacing: -0.44px;
  color: $black;
}

.authInput {
  @include margin-bottom(24px);

  textarea {
    width: 100%;
    resize: none;
    border: 1px solid $xslight-gray;
    height: 80px;

    &:focus {
      border-color: $branding1;
    }
  }

  .errorInput {
    border-color: $red;
  }
}
@import 'styles/mia';


.quizNodeWrapper {
    @include padding-top(20px);

    @include media-breakpoint-down(sm) {
        @include padding-bottom(40px);
    }

    .duration {
        text-transform: uppercase;
        @include font-size(11px);
        @include margin-bottom(25px);
        font-weight: 300;
        letter-spacing: 1.22px;
        color: $xs-dark-gray;
        line-height: 1;
    }

    .progressBar {
        @include margin-bottom(24px);

        .progress {
            height: 5px;
        }
    }

    .questionCount {
        @include font-size(24px);
        letter-spacing: -0.67px;
        font-weight: 300;
        line-height: 1;
        color: $black;
    }

    .quizContent {
        @include padding-top(45px);
    }

    .retakeWrap {
        text-align: center;
        .retakeButtons {
            display: flex;
            margin: 20px auto;
            justify-content: center;
        }
        button {
            display: block;
        }

        a {
            margin-left: 64px;
        }

        .retakeImage {
            @include margin-bottom(50px);
        }

        .subTitle {
            @include font-size(24px);
            letter-spacing: -0.67px;
            font-weight: 300;
            line-height: 1;
            color: $black;
            @include margin-bottom(20px);
        }

        .title {
            @include font-size(36px);
            font-weight: 500;
            letter-spacing: -1px;
            line-height: 1.1;
            @include margin-bottom(40px);
        }
    }

    .contentWidth {
        max-width: 694px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
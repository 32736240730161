@import 'styles/mia';

.pageHeading {
    @include font-size(24px);
    font-weight: 500;
    letter-spacing: -0.67px;
    @include margin-bottom(50px);
}

.sectionHeading {
    @include font-size(18px);
    font-weight: 300;
    line-height: 18px;
    @include padding-bottom(12px);
    @include margin-bottom(22px);
    border-bottom: 1px solid $medium-gray;
}

.membershipStatus {
    @include margin-bottom(80px);
    display: flex;
    justify-content: space-between;

    .statusLeft {
        .billedInfoTop {
            font-weight: 500;
            @include margin-bottom(5px);

            span {
                font-weight: 700;
                color: $primary;
            }
        }

        .billedInfoBottom {
            @include font-size(14px);
        }
    }
}

.hidden {
    visibility: hidden;
}

.infoHover {
    display: inline-block;
  
    @include border-radius(50%);
  
    background: $gray;
    color: $white;
    height: 16px;
    width: 16px;
    line-height: 16px;
    font-weight: 500;
  
    @include margin-left(5px);
    @include font-size(12px);
  
    text-align: center;
  }
  
  .shortMess {
    display: inline-block;
  
    @include margin-left(5px);
  
    color: $branding1;
  
    @include font-size(14px);
  }

.cardInput {
    margin: 0;
    min-height: 50px;
    border-radius: 0 !important;
    color: #1c1c1c !important;
    padding-top: 14px !important;
}

.authLabel {
    @include font-size(14px);
    @include margin-bottom(5px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.44px;
    color: $black;
}

.authInput {
    @include margin-bottom(24px);

    position: relative;

    &.authInputPassword {
        input {
            background-image: url(../../../../assets/svgs/lock.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 20px) center;
            @include padding-right(45px);
        }
    }

    .errorInput {
        border-color: $red;
    }
}

.billProfileWrapper {
    max-width: 800px;
    width: 100%;
}

.pastTransListOuter {
    @include margin-top(60px);

    @include media-breakpoint-down(md) {
        @include margin-top(0);
    }
}

@include media-breakpoint-down(md) {
    .authFormWrap {
        @include margin-bottom(40px);
    }
}

.savedCardList {
    @include margin-top(45px);
    @include margin-bottom(45px);

    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px dashed $gray;
        @include padding(8px 8px 8px 20px);
        @include margin-bottom(15px);
    }

    .numberType {
        letter-spacing: -0.44px;
        @include font-size(14px);
        font-weight: bold;
        line-height: 1.4;
    }
}


@include media-breakpoint-down(xs) {
    .authButton {
        @include margin-bottom(30px);
    }
}

.pastTransList {
    .list {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include font-size(14px);
        letter-spacing: -0.44px;
        border-bottom: 1px dashed $medium-gray;
        @include margin-bottom(20px);
        @include padding-bottom(20px);

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            line-height: 1.8;
        }

        &:last-child {
            border: none
        }

        .lessonName {
            @include font-size(12px);
            color: $gray;

            div {
                @include font-size(16px);
                color: $black;
            }

        }

        .lessonPrice {
            font-weight: 500;
        }
    }
}

.error {
    color: $light-red;
}

.apiError {
    color: $light-red;
    margin-top: 10px;
}

.apiResult {
    margin-top: 10px;
}
@import 'styles/mia';

.heading {
    @include font-size(24px);
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.67px;
    color: $black;
    @include margin-bottom(48px);
}


.gridListing {
    @include margin-bottom(48px);

    .list {
        @include margin-bottom(30px);
    }

}
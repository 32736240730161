@import 'styles/mia';

.userStats {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @include padding-bottom(56px);
    @include margin-bottom(48px);
    @include margin-top(25px);
    border-bottom: 1px solid $light-gray;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}


.userStatsList {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    text-align: center;
    max-width: 180px;
    position: relative;
    @include margin-right(40px);
    @include padding-right(40px);

    &.activeStat, &:hover {
        color: $branding1;
    }

    @include media-breakpoint-down(sm) {
        @include margin-right(0);
        @include padding-right(0);
        @include margin-bottom(15px);
        max-width: 170px;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -40px;
        height: 80px;
        width: 1px;
        background: $light-gray;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &:last-child {
        @include margin-right(0px);
        @include padding-right(0px);

        &:after {
            display: none;
        }
    }
}

.numberStats {
    @include font-size(60px);
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1.67px;
    @include margin-bottom(5px);
}

.lableStats {
    @include font-size(20px);
    line-height: 1.2;
    font-weight: 700;
}
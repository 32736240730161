@import "../../styles/mia";

.modalHeading {
  @include font-size(28px);
  font-weight: 500;
  line-height: 1.2;
  color: $black;
  @include margin-bottom(10px);
}

.modalContainer.modal {

  .modalImg {
    @include margin-bottom(30px);
  }

  .modalBody {
    @include padding(60px 80px 0);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: $black;
    text-align: center;

    @include media-breakpoint-down(sm) {
      @include padding(40px 30px 0);
    }
  }

  .modalFooter {
    justify-content: center;
    @include padding-bottom(60px);

    @include media-breakpoint-down(sm) {
      @include padding-bottom(30px);
    }
  }
}



.headerWrap {
  
  .p-static {
    position: static;
  }

  box-shadow: 0 2px 0 0 $light-gray;

  @include media-breakpoint-down(md) {
    overflow: hidden;
    @include padding(10px 0);

    >.container {
      >.row {
        >div:last-child {
          position: static;
        }
      }
    }
  }

  .closeMenu {
    display: none;
  }

  .miaBranding {
    @include padding(0);
    @include margin-top(0px);
    @include margin-right(20px);

    .mainLogo {
      max-width: 150px !important;
    }

    @include media-breakpoint-down(md) {
      order: 2;
      @include margin-right(0);
      position: relative;
      z-index: 1;
      width: 155px;
      @include media-breakpoint-down(xs) {
        width: 120px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-collapse {
      order: 3;
    }
  }

  .searchOuter {
    position: relative;
    width: 200px;
    @include margin-right(30px);

    @include media-breakpoint-down(md) {

      .searchInputBox {
        position: absolute;
        bottom: -60px;
        width: 180px;
        right: -20px;
        z-index: 111;
        @include margin-right(0);
      }
    }
  }

  .searchIcon {
    position: absolute;
    right: 0;
    top: 5px;
    color: $light-gray;

    @include media-breakpoint-down(md) {
      @include font-size(24px);
      top: -8px;
      right: -10px;
    }
  }

  input {
    &.transparent {
      border: none;
      padding: 0;
      border-bottom: 1px solid transparent;
      @include font-size(14px);
      min-height: 30px;
      height: 30px;

      @include media-breakpoint-down(md) {
        border-bottom: 1px solid $gray;
      }

      &:focus,
      &:active {
        // border-bottom: 1px solid lighten($branding1, 0.5%);
        border-bottom: 1px solid $branding-lighten-05;
      }

      &::placeholder {
        color: $gray;
        font-family: "Open Sans";
        font-style: italic;
      }
    }
  }
}
.mainMenu {

  @include media-breakpoint-down(md) {
    &.desktopMenu {

    }
  }

  &.mobileMenu {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;

      .miaBranding {
        display: none
      }
    }
  }
  &.navbar {
    @include padding(18px 0 0);
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      @include padding(0);
      justify-content: flex-start;
      align-items: center;
      position: static;

      .navbar-toggler {
        order: 1;
        @include margin-right(10px);
        @include padding(0);
        border: none;
        @include margin-top(2px);
        color: $branding1;
        outline: 0;
        position: absolute;
        z-index: 1;
        right: 0;
        top: 10px;

        span {
          background-image: url(../../assets/svgs/download.svg);
        }
      }
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(md) {
      @include margin-top(15px);

      .headerButtons {
        justify-content: center;
        align-items: center;
        flex-direction: column;

        a {
          @include margin-top(10px);
          min-width: 200px;

          button {
            width: 100%;
          }
        }
      }

    }

    a.nav-link {
      @include font-size(14px);
      @include padding(5px 5px 20px);
      @include margin-right(25px);
      font-weight: 500;
      color: $black;
      position: relative;

      @include media-breakpoint-down(md) {
        @include margin-right(0px);
        @include padding(10px 0);
        text-align: center;
        border-bottom: 1px solid $xslight-gray;
      }

      &:after {
        content: "";
        width: 0;
        position: absolute;
        bottom: -1px;
        left: 50%;
        background-color: $branding1;
        height: 1px;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
      }

      &:hover {
        color: $branding1;

        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }
}

.headerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include padding-top(14px);

  @include media-breakpoint-down(md) {
    @include padding-top(0);
    @include padding-right(40px);
  }

  @include media-breakpoint-down(md) {
    min-height: 34px;
    position: absolute;
    right: 15px;
    top: 9px;
  }

  .headerButtons {
    a:last-child {
      @include margin-left(5px);
    }
  }
}

.profileInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include margin-right(15px);

  @include media-breakpoint-down(md) {}

  .proifleName {
    .dropdown.dropdownButton {
      .dropdown-item {
        @include padding(5px 10px);
        @include font-size(12px);
        font-family: $graphik;
        font-weight: 500;

        &.active,
        &:active {
          background-color: $gray;
        }
      }

      .dropdown-menu {
        @include padding(5px 0);
        // @include media-breakpoint-down(md) {
        //   &.dropdown-menu-right {
        //     right: auto;
        //     left: 0;
        //   }
        // }
      }

      button.btn {
        @include padding(8px 0);
        background: none;
        outline: 0;
        box-shadow: none;
        border: none;
        font-family: $graphik;
        font-weight: 500;
        color: $black;
        text-align: left;
        @include font-size(12px);
        position: relative;
        min-width: 0;

        &:after {
          position: absolute;
          right: -15px;
          top: 13px;
          border-bottom-color: $light-gray;
          border-top-color: $light-gray;
          border-top-width: 6px;
          border-left-width: 5px;
          border-right-width: 5px;
        }
      }
    }
  }

  .profileImg {
    border: 1px solid $light-gray;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include margin-right(10px);

    svg {
      width: 20px;
      height: auto;
    }

    img {
      border-radius: 50%;
    }
  }
}

.headerButtons {
  a.nav-link {
    @include font-size(14px);
    @include padding(10px 16px);

    background-color: $branding1;
    border: 1px solid transparent;
    border-radius: 3rem;
    color: $white;
    font-family: $graphik;
    font-weight: 500;
    line-height: 1;

    &:after {
      display: none;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active:focus,
    &.btn-primary:not(:disabled):not(.disabled):active:focus {
      // background-color: lighten($branding1, 5%);
      background-color: $branding-darken-10;
      border-color: transparent;
      color: $white;
      background-image: none;
      outline: 0;
      box-shadow: none;
    }

    &.blank-btn {
      background-color: transparent;
      color: $black;
      border-color: transparent;

      &:focus,
      &:hover {
        background-color: $light-gray;
        border-color: transparent;
        color: $black;
      }
    }

    &.ghostBtn {
      background-color: transparent;
      color: $branding1;
      border: 1px solid $branding1;

      &:focus,
      &:hover {
        background-color: $light-gray;
        color: $dark-gray;
        border: 1px solid $light-gray;
      }
    }
  }
}
@import "../../../../styles/mia";


.courseCompleteWrapper {
    text-align: center;
    @include margin-bottom(100px);

    .imgWrapper {
        img {
            width: 102px;
            height: 102px;
        }

        @include margin-bottom(18px);
    }

    .completeHeading {
        @include font-size(36px);
        letter-spacing: -1px;
        font-weight: 500;
        line-height: 1;
        color: $black;
        @include margin-bottom(18px);
    }

    .btnWrapper {
        :global {
            button.btn {
                min-width: 280px;
            }
        }
    }
}
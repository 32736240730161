@import "../../../../styles/mia";

.courseVideo {
    height: 446px;
    max-width: 793px;
    width: 100%;
    background: $black;
    @include margin-bottom(28px);
}

.courseCategory {
    color: $branding2;
    @include font-size(14px);
    line-height: 1;
    font-weight: 700;
    @include margin-bottom(25px);
}

.courseHeading {
    @include font-size(36px);
    line-height: 1.05;
    letter-spacing: -1px;
    color: $black;
    font-weight: 500;
    @include margin-bottom(10px);
}

.subCourseHeading {
    @include font-size(16px);
    line-height: 1.2;
    letter-spacing: -1px;
    color: $black;
    font-weight: 500;
}

.controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    color: $xslight-gray;
    letter-spacing: 1.22px;
    @include font-size(11px);
    @include margin-top(30px);
    @include margin-bottom(45px);

    div {
        @include margin-right(10px);
    }

    div:last-child {
        margin-left: auto;
    }
}

.downloadCourse {
    .heading {
        font-family: $opensans;
        color: $dark-gray;
        font-weight: 600;
        @include margin-bottom(20px);
    }
}

.dowloadList {
    >div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include margin-bottom(15px);
        @include padding-bottom(15px);
        border-bottom: 1px solid $xslight-gray;

        &:last-child {
            border-bottom: none;
        }

        a {
            font-family: $opensans;
            font-weight: 600;
        }

        .size {
            margin-left: auto;
            font-family: $opensans;
            font-weight: 600;
            color: $medium-gray;
            letter-spacing: -0.33px;
            @include font-size(12px);
        }

        svg {
            @include margin-right(16px);
        }
    }
}

.description {
    color: $xs-dark-gray;
    white-space: pre-wrap;
    @include font-size(14px);
    line-height: 1.4;
    @include media-breakpoint-down(sm) {
      @include margin-bottom(40px);
      @include padding-bottom(40px);
    }
}

.courseInstructor {
    .instHeading {
        @include font-size(14px);
        font-weight: 500;
        line-height: 1;
        @include margin-bottom(28px);
        @include margin-top(28px);
    }
    @include margin-bottom(25px);
}

.intructorDetail {
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;

    .img {
        flex-basis: 70px;

        img {
            @include border-radius(50%);
            border: 1px solid $gray;
        }
    }

    .insDetailInner {
        flex-basis: calc(100% - 70px);
        @include padding-left(18px);

        .instName {
            @include font-size(14px);
            font-weight: 700;
            line-height: 1;
            color: $branding1;
            @include margin-bottom(18px);
        }

        .shortDes {
            @include font-size(12px);
            font-family: $opensans;
            color: $xs-dark-gray;
            letter-spacing: -0.33px;
            width: 100%;
        }
    }
}

.aboutCourse {
    .btnWrapper {
        @include margin-bottom(15px);
        text-align: right;
        button {
            height: 48px;
            padding: 0px !important;
            min-width: 150px !important;
        }
    }
}

.otherCourses {
    @include margin-top(130px);

    .sectionHeading {
        @include font-size(24px);
        letter-spacing: -0.67px;
        line-height: 1;
        font-weight: 500;
        @include margin-bottom(45px);
    }
}
@import 'styles/mia';

.listWrapper {
    background: $white;
    border: 1px solid $light-gray;
    position: relative;
    min-height: 100%;
    @include border-radius(4px);
    cursor: pointer;
    position: relative;
    padding-bottom: 30px;

    .disabled {
        opacity: 0.5;
        curser: default;
    }

    svg.check {
        position: absolute;
        bottom: 15px;
        right: 15px;
        color: $white;
        @include font-size(28px);
        border-radius: 50%;
        background: $branding2;
        margin-left: auto;
        font-weight: 800;
        border: 2px solid $branding2;
    }

    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 146px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .courseDetails {
        @include padding(15px 20px);
    }

    .courseCat {
        @include font-size(14px);
        min-height: 14px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -0.39px;
        @include margin-bottom(8px);
        color: $branding1;

        .green {
            // color: $branding2;
            color: $branding1;
        }

        .purple {
            color: $branding1;
        }

        .blue {
            color: $branding1;
            // color: $branding1-1;
        }
    }

    .courseTitle {
        @include font-size(24px);
        line-height: 26px;
        color: $black;
        @include margin-bottom(12px);
        letter-spacing: -0.67px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            max-width: 200px;
        }
    }

    .courseDescription {
        @include font-size(14px);
        font-family: $opensans;
        line-height: 16px;
        color: $xs-dark-gray;
        letter-spacing: -0.39px;
        @include margin-bottom(20px);
    }

    .memberCount {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        bottom: 20px;

        svg.users {
            color: $medium-gray;
        }

        .text {
            @include font-size(12px);
            font-family: $opensans;
            color: $xs-dark-gray;
            font-weight: 600;
            @include margin-left(5px);

            span {
                font-weight: 700;
            }
        }
    }

    .partnerContent {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-weight: 600;
        font-size: 11px;
        color: blue;
        background: aliceblue;
        border-radius: 20px;
        text-align: center;
        padding: 2px 10px;

        img {
            margin-right: 4px;
        }
    }
}
@import '../../../styles/mia';

.customRadio {
    display: inline-block;

    div {
        position: relative;
        padding-left: 0;

        &.highlightBlack.form-check {
            label.form-check-label {
                &:after {
                    content: '';
                    position: absolute;
                    left: 6px;
                    top: 8px;
                    width: 0px;
                    height: 0px;
                    background-color: #000000;
                    @include border-radius(50%);
                }
            }
        }
        
        &.lgCheckbox.form-check {
            input {
                @include margin-top(3px);

                &:checked {
                    ~label {

                        &:after {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }

            label {
                @include padding-left(40px);

                &:before {
                    width: 25px;
                    height: 25px;
                }

                &:after {
                    left: 6px;
                    top: 8px;
                    width: 0px;
                    height: 0px;
                }
            }
        }

        label.form-check-label {
            @include font-size(22px);
            letter-spacing: -0.61px;
            font-weight: 500;
            position: relative;
            color: $black;
            @include padding-left(40px);

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                width: 18px;
                height: 18px;
                background-color: transparent;
                border: 1px solid $xs-dark-gray;
                @include border-radius(50%);
            }

            &:after {
                content: '';
                position: absolute;
                left: 4px;
                top: 6px;
                width: 0px;
                height: 0px;
                background-color: $branding2;
                @include border-radius(50%);
            }

            &.completed {
                &:before {
                    border: 1px solid lighten($xs-dark-gray, 50%);
                }

                &:after {
                    content: " ";
                    display: block;
                    width: 4px;
                    height: 8px;
                    border: solid lighten($xs-dark-gray, 50%);
                    border-width: 0 1px 1px 0;
                    position: absolute;
                    left: 7px;
                    top: 6px;
                    transform: rotate(45deg);
                    border-radius: 0;
                    background: none;
                }
            }

        }

        input.form-check-input {
            margin-top: 0;
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: 1;
            margin: 0;

            &.is-valid {
                ~label {
                    color: $black;
                }
            }

            &:checked {
                ~label {
                    &:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}
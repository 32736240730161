@import 'styles/mia';

.newCardForm {
    margin: 0 auto;
    max-width: 100%;
    border: 1px solid $medium-gray;
    @include padding(5px 10px 5px 25px);

    :global {
        .row {
            align-items: center;
        }
    }

    .addCardBtn {
        text-align: right;
    }

    .error {
        color: $light-red;
        font-size: 14px;
        padding: 0 15px;
    }
}
@import '../../styles/mia';

.helpOuter {
    border-top: 1px solid $medium-gray;
    @include padding-top(22px);
    @include margin-top(50px);
    max-width: 182px;

    svg {
        height: auto;
        width: 65px;
        @include margin-bottom(5px);
    }

    .content {
        @include font-size(16px);
        line-height: 1.2;
        margin-bottom: 35px;

        a {
            font-weight: bold;
        }
    }
}
@import '../../../../styles/mia';

.dashMenu {
    ul {
        @include padding(0);
        list-style: none;

        li {
            @include margin-bottom(20px);

            a.menuItem {
                color: $dark-gray;
                line-height: 1.4;
                display: block;


                &:before {
                    content: '';
                    background-image: url(../../../../assets/imgs/dashboard/sprite.png);
                    background-repeat: no-repeat;
                    background-position: 1px 3px;
                    background-size: 63px auto;
                    display: inline-block;
                    vertical-align: middle;
                    @include margin-right(20px);
                    height: 28px;
                    width: 29px;
                }

                &.active {
                    color: $branding1;

                    &:before {
                        background-position: -33px 3px;
                    }
                }

                &.quizes {

                    &:before {
                        background-position: 1px -26px;
                    }

                    &.active {

                        &:before {
                            background-position: -33px -26px;
                        }
                    }
                }

                &.topics {

                    &:before {
                        background-position: -1px -59px;
                    }

                    &.active {

                        &:before {
                            background-position: -35px -59px;
                        }
                    }
                }

                &.library {

                    &:before {
                        background-position: 2px -95px;
                    }

                    &.active {

                        &:before {
                            background-position: -32px -95px;
                        }
                    }
                }

                &.certificate {

                    &:before {
                        background-position: 2px -127px;
                    }

                    &.active {

                        &:before {
                            background-position: -32px -127px;
                        }
                    }
                }

                &.billing {

                    &:before {
                        background-position: -1px -158px;
                    }

                    &.active {

                        &:before {
                            background-position: -35px -158px;
                        }
                    }
                }

                &.support {

                    &:before {
                        background-position: -1px -192px;
                    }

                    &.active {

                        &:before {
                            background-position: -35px -192px;
                        }
                    }
                }

                &.signOut {

                    &:before {
                        background-position: 3px -223px;
                    }

                    &.active {

                        &:before {
                            background-position: -31px -223px;
                        }
                    }
                }
            }
        }
    }
}

.lineBreak {
    border-top: 1px solid $medium-gray;
    @include padding-top(22px);
    @include margin-top(50px);
    max-width: 182px;
}

.externalLinks {
    ul {
        @include padding(0);
        list-style: none;

        li {
            @include margin-bottom(20px);

            a.externalMenuItem {
                color: $dark-gray;
                line-height: 1.4;
                display: block;


                svg {
                    margin-right: 25px;
                }
                
            }
        }
    }
}

@import '../../../../styles/mia';


.serviceListing {
    @include padding(170px 0 120px);
}

.list {
    display: flex;
    flex-direction: column;

    @include padding-right(100px);

    @include media-breakpoint-down(md) {
        @include padding-right(30px);
        @include margin-bottom(50px);
        align-items: center;
        text-align: center;
    }

    @include media-breakpoint-down(sm) {
        @include padding-right(0px);
        @include margin-bottom(50px);
    }

    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        @include margin-bottom(25px);
        height: 78px;
        width: 78px;
        background: $image-background-color;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }

    .stepCount {
        line-height: 1;
        text-transform: uppercase;
        color: $black;
        font-weight: 700;
        letter-spacing: 0.88px;
        @include margin-bottom(5px);
    }

    .heading {
        @include font-size(28px);
        line-height: 48px;
        color: $dark-gray;
    }

    .description {
        @include font-size(16px);
        letter-spacing: -0.44px;
        color: $dark-gray;
        font-family: $opensans;
        font-weight: 300;
        @include margin-bottom(20px);
    }

    .bannerButtons {
        button {
            @include font-size(16px);
        }
    }
}
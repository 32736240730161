@import 'styles/mia';

.heading {
    @include font-size(24px);
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.67px;
    color: $black;
    @include margin-bottom(48px);
    .description {
        @include font-size(14px);
        font-family: $opensans;
        line-height: 16px;
        margin-top: 10px;
        color: $xs-dark-gray;
    }
}

.status {
    span {
        letter-spacing: -0.31px;
        display: flex;
        @include font-size(12px);
        @include border-radius(4px);
        @include margin-top(15px);
        font-weight: 500;
        min-width: 162px;
        line-height: 28px;
        max-width: 162px;
        justify-content: center;
        align-items: center;
        background: $light-gray;

        svg {
            @include font-size(14px);
            @include margin-right(6px);
            vertical-align: middle;
        }
    }
}

.gridListing {
    @include padding-bottom(70px);
    @include margin-bottom(48px);

    &.noBorder {
        border-bottom: none;
        @include margin-bottom(148px);
    }
}
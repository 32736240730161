@import '../../styles/mia';

.termsContainer {
  @include margin-top('25px');
  color: gray;

  .sectionHeader {
    font-size: 1.125rem;
    letter-spacing: -0.5px;
    font-family: "Open Sans", sans-serif;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    @include font-size(15px);
  }
}

@import '../../../../styles/mia';

.careerMapOuter {
  @include margin-top(26px);

  .heading {
    @include font-size(22px);
    letter-spacing: 2.44px;
    text-transform: uppercase;
    color: $black;
    font-family: $opensans;
    font-weight: bold;
    line-height: 1.35;
    @include margin-bottom(65px);

    @include media-breakpoint-down(sm) {
      @include margin-bottom(40px);
    }
  }
}

.careerList {
  .list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include margin-bottom(65px);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      @include margin-bottom(45px);
    }

    .imgWrapper {
      @include padding-right(89px);

      @include media-breakpoint-down(sm) {
        @include padding-right(0);
      }

      img {
        max-width: 196px;

        @include media-breakpoint-down(sm) {
          max-width: 120px;
        }
      }
    }

    .careerDetails {
      border-left: 1px solid $gray;
      @include padding-left(45px);
      height: 130px;
      display: flex;
      justify-content: center;
      font-family: $opensans;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        @include padding-left(0);
        border: none;
        height: auto;
        @include margin(15px 0 20px);
      }

      .careerheading {
        @include font-size(28px);
        font-weight: bold;
        letter-spacing: 0.78px;
      }

      .careerSubheading {
        @include font-size(20px);
        letter-spacing: 0.56px;
      }
    }
  }
}
@import 'styles/mia';

img {
    max-width: 100%;
}

.carouselOuter {

    .captionSection {
        position: static;
        text-align: left;
        min-height: 510px;

        @include media-breakpoint-down(lg) {
            min-height: 0;
        }
    }
}

@include media-breakpoint-down(lg) {
    .sliderPosition {
        >div:first-child {
            order: 2;
        }
    }
}

.carouselWrapper {
    position: relative;
    z-index: 2;
}

.subheading {
    @include font-size(14px);
    letter-spacing: 2px;
    color: $branding1;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    @include margin-bottom(15px);
}

.heading {
    @include font-size(42px);
    font-weight: 300;
    letter-spacing: -1.33px;
    color: $black;
    line-height: 1.1;
    @include margin-bottom(15px);
    @include padding-right(0px);
}

.author {
    @include font-size(28px);
    font-weight: 500;
    letter-spacing: -0.78px;
    color: $black;
    line-height: 1;
    @include margin-bottom(10px);
}

.location {
    @include font-size(16px);
    font-weight: 300;
    letter-spacing: -0.44px;
    color: $gray;
}

.imgWrapper {
    right: 0;
    top: 0;
    position: absolute;
    max-width: 700px;
    z-index: -1;

    @include media-breakpoint-down(lg) {
        position: static;
        order: 1;
    }

    img {
        width: 500px;
        height: auto;
        @include border-radius(10px);
    }
}
// src\components\Button\Button.scss

@import '../../../styles/mia';

.miaButton {
    +.miaButton {
        margin-left: 10px;
    }

    &.miaBtn {
        @include font-size(14px);
        @include padding(10px 16px);

        background-color: $branding1;
        border: 1px solid transparent;
        border-radius: 3rem;
        color: $white;
        font-family: $graphik;
        font-weight: 500;
        line-height: 1;

        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active:focus,
        &.btn-primary:not(:disabled):not(.disabled):active:focus {
            // background-color: lighten($branding1, 15%);
            background-color: $branding-darken-10;
            border-color: transparent;
            color: $white;
            background-image: none;
            outline: 0;
            box-shadow: none;
        }


        &.small {
            @include padding(15px 10px);
            @include font-size(14px);
            font-family: $graphik;
            font-weight: bold;
            border-radius: 4px;
            min-width: 110px;
            text-align: center;
            transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;
        }

        &.dangerBtn {
            @include padding(15px 10px);
            @include font-size(14px);
            font-family: $graphik;
            font-weight: bold;
            border-radius: 4px;
            min-width: 110px;
            text-align: center;
            color: $dark-red;
            background-color: $red;
            transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;

            &:hover,
            &:focus,
            &:active,
            &:active:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active:focus,
            &.btn-primary:not(:disabled):not(.disabled):active:focus {
                background-color: lighten($red, 15%);
                background-image: none;
                outline: 0;
                box-shadow: none;
            }
        }

        &.dangerBtnSm {
            @include padding(10px);
            @include font-size(12px);
            font-family: $graphik;
            font-weight: bold;
            border-radius: 4px;
            min-width: 80px;
            text-align: center;
            color: $dark-red;
            background-color: $red;
            transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;

            &:hover,
            &:focus,
            &:active,
            &:active:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active:focus,
            &.btn-primary:not(:disabled):not(.disabled):active:focus {
                background-color: lighten($red, 15%);
                background-image: none;
                outline: 0;
                box-shadow: none;
            }
        }

        &.btnGray {
            background-color: $light-gray;
            color: $white;
            border: 1px solid $light-gray;

            &:hover,
            &:focus,
            &:active,
            &:active:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active:focus,
            &.btn-primary:not(:disabled):not(.disabled):active:focus {
                background-color: darken($light-gray, 15%);
                border-color: transparent;
                color: $white;
                background-image: none;
                outline: 0;
                box-shadow: none;
            }
        }

        &.btnGrayDark {
            background-color: $xs-dark-gray;
            color: $white;
            border: 1px solid $xs-dark-gray;

            &:hover,
            &:focus,
            &:active,
            &:active:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active:focus,
            &.btn-primary:not(:disabled):not(.disabled):active:focus {
                background-color: lighten($xs-dark-gray, 15%);
                border-color: transparent;
                color: $white;
                background-image: none;
                outline: 0;
                box-shadow: none;
            }
        }

        &.large {
            @include padding(20px 30px);
            @include font-size(18px);
            font-family: $graphik;
            font-weight: bold;
            border-radius: 3rem;
            min-width: 180px;
            text-align: center;
            transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border ease-in-out;
        }

        &.blank {
            background-color: transparent;
            color: $black;

            &:focus,
            &:hover {
                background-color: $light-gray;
            }

            &.branding {
                @include padding(0);
                color: $branding1;
                background: transparent;

                &:hover,
                &:focus,
                &:active,
                &:active:focus,
                &:not(:disabled):not(.disabled).active,
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active:focus,
                &.btn-primary:not(:disabled):not(.disabled):active:focus {
                    background-color: transparent;
                    color: $black;
                }
            }
        }

        &.ghostBtn {
            background-color: transparent;
            color: $branding1;
            border: 1px solid $branding1;

            &:focus,
            &:hover {
                background-color: $light-gray;
                color: $dark-gray;
                border: 1px solid $light-gray;
            }
        }

        &.ghostBtnGray {
            background-color: transparent;
            color: $xs-dark-gray;
            border: 1px solid $medium-gray;

            &:focus,
            &:hover {
                background-color: $light-gray;
                color: $dark-gray;
                border: 1px solid $light-gray;
            }
        }

        &.branding {
            background-color: $branding1;
            color: $white;

            &:focus,
            &:hover {
                // background-color: darken($branding1, 10%);
                background-color: $branding-darken-10;
                // backdrop-filter: brightness(90%);
            }
        }

        &.btnArrowLeft {
            background: url(../../../assets/svgs/arrow-left.svg) no-repeat left 25px;
            background-size: 30px auto;
            @include border-radius(0);
            padding-left: 0;
            border: none;
            color: $xs-dark-gray;

            &:hover,
            &:focus,
            &:active,
            &:active:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active:focus,
            &.btn-primary:not(:disabled):not(.disabled):active:focus {
                color: $black;
                background: url(../../../assets/svgs/arrow-left.svg) no-repeat left 25px;
                background-size: 30px auto;
            }
        }
    }
}
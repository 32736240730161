@import 'styles/mia';

.quizSummaryWrapper {
    min-height: 600px;
    @include padding-top(50px);

    .relatedCourse {
        margin-bottom: 20px;
        font-size: 18px;
        color: #979797;
    }
}

.quizTop {
    position: relative;
    @include margin-bottom(65px);
    @include padding-top(50px);
    @include padding-bottom(11px);
    background: url(../../assets/imgs/dashboard/dashboard-top.png) no-repeat center right;

    @include media-breakpoint-down(md) {
        background-position: center left;
    }

    .quizImage {
        width: 284px;
        height: 225px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        right: 26px;
        top: -30px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .quizHeading {
        @include font-size(24px);
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: -0.67px;
    }

    .quizTitle {
        @include font-size(40px);
        text-transform: capitalize;
        font-weight: 300;
        line-height: 1.3;
        max-width: calc(100% - 290px);
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }

    .quizCategory {
        @include font-size(12px);
        letter-spacing: 1.5px;
        font-weight: 500;
        color: $gray;
        text-transform: uppercase;
    }
}

.quizContent {
    text-align: center;
    padding: 50px;
    .quizLock {
        @include margin-bottom(30px);
    }
    .duration {
        text-transform: uppercase;
        @include font-size(11px);
        font-weight: 300;
        letter-spacing: 1.22px;
        color: $xs-dark-gray;
        line-height: 1;
        text-transform: uppercase;
        @include margin-bottom(10px);
    }
    .title {
        @include font-size(36px);
        font-weight: bold;
        @include margin-bottom(10px);
    }
    .desc {
        font-size: 18px;
        border-bottom: 1px solid #f1f1f1;
        color: #979797;
        @include margin-bottom(30px);
        @include padding-bottom(30px);
    }
    .actionNote {
        font-size: 13px;
        color: #979797;
        @include margin-bottom(20px);
    }
    .startBtn {
        width: 150px;
        margin: 0 auto;
    }
}
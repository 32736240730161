@import 'styles/mia';

.questionWrapper {
    .quizName {
        @include font-size(36px);
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 1.1;
        @include margin-bottom(40px);

        &.resultQuestion {
            @include margin-bottom(20px);        
        }
    }

    .quizOptions {
        .quizHeading {
            @include font-size(16px);
            font-weight: 700;
            letter-spacing: -.44px;
        }
    }

    .btnWrapper {
        @include margin-bottom(25px);
    }

    .optionsList {
        @include margin-bottom(20px);

        >div {
            @include padding(20px 0);
            display: flex;
            border-bottom: 1px solid $xslight-gray;
            &.result {
                @include padding(20px 15px);
                .form-check-label {
                    color: #000000;
                }
                &.correct {
                    background-color: #edfef8;
                }
                &.wrong {
                    background-color: #fef3ef;
                }
                .rightIcon {
                    margin-left: auto;
                    align-self: center;
                    svg {
                        color: $branding2;
                        font-size: 28px;
                    }
                }
                .wrongIcon {
                    margin-left: auto;
                    align-self: center;
                    svg {
                        color: #f07645;
                        font-size: 28px;
                    }
                }
            }
        }
    }

    .imgWrapper {
        @include margin-bottom(45px);
    }

    .contentWidth {
        max-width: 694px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
@import 'styles/mia';

.savedCardList {
    @include margin-top(45px);
    @include margin-bottom(45px);

    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px dashed $gray;
        @include padding(8px 8px 8px 20px);
        @include margin-bottom(15px);
    }

    .numberType {
        letter-spacing: -0.44px;
        @include font-size(14px);
        font-weight: bold;
        line-height: 1.4;
    }
}
// src/pages/Login/Login.module.scss

@import '../../styles/mia';


.subsOuter {
    @include padding(150px 15px);
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(sm) {
        @include padding(50px 15px);
    }


    .buttonOuter {
        @include margin-top(40px);

        button {
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
        }
    }

    .subsContent {
        max-width: 520px;

        .paymentDetails {
            @include margin-bottom(25px);

            .priceDetails {
                @include font-size(14px);
                display: flex;
                justify-content: space-between;
                @include padding(15px 0);
                border-bottom: 1px solid $xslight-gray;
                align-items: center;
                cursor: pointer;
                &.activePlan {
                    .coursePrice {
                        background: $branding2;
                        border-radius: 10px;
                        color: #fff;
                        padding: 8px 20px;
                    }
                }
                .coursePrice {
                    font-weight: 500;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .subsheading {
            @include font-size(20px);
            font-weight: bold;
            @include margin-bottom(10px);
        }

        .subsSubheading {
            @include font-size(40px);
            font-weight: 300;
            color: $black;
            line-height: 1;
            @include margin-bottom(15px);
        }

        .subsDesc {
            @include font-size(14px);
            @include margin-bottom(10px);
            line-height: 1.4;
        }

        .confirmSec {
            @include margin-top(40px);

            .sectionHeading {
                @include font-size(14px);
                font-weight: 500;
                @include margin-bottom(10px);
            }

            .conHeading {
                @include font-size(22px);
                font-weight: 500;
                letter-spacing: -.5px;
            }
        }

        .error {
            margin-top: 20px;
        }

        .couponCode {
            position: relative;
            margin-top: 20px;
            margin-bottom: 40px;
            button {
                position: absolute;
                right: 12px;
                top: 7px;
            }
            .error {
                color: $light-red;
                font-size: 14px;
                margin-top: 5px;
                float: right;
            }
        }
    }
}
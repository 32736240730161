@import 'styles/mia';

.mainCertificationsHeader {
    @include padding(30px 0 20px);

    h1 {
        @include font-size (52px);
        letter-spacing: -1.44px;
        line-height: 1;
        font-weight: bold;
        margin: 0;
        color: $dark-gray;
        max-width: 600px;
        @include margin-bottom(10px);

        @include media-breakpoint-down(md) {
            line-height: 1.2;
        }
    }

    .pageSubHeading {

        @include font-size (14px);
        letter-spacing: -0.39px;
        color: $xs-dark-gray;
        max-width: 550px;

        @include media-breakpoint-down(md) {
            @include margin-bottom(15px);
        }
    }

    @include media-breakpoint-down(md) {
        svg {
            max-width: 250px;
        }
    }

}
@import "styles/mia";


.courseCategory {
    color: $branding2;
    @include font-size(14px);
    line-height: 1;
    font-weight: 700;
    @include margin-bottom(25px);
}

.courseHeading {
    @include font-size(36px);
    line-height: 1.05;
    letter-spacing: -1px;
    color: $black;
    font-weight: 500;
    @include margin-bottom(18px);
}

.subCourseHeading {
    @include font-size(16px);
    line-height: 1.2;
    letter-spacing: -1px;
    color: $black;
    font-weight: 500;
    @include margin-bottom(14px);
}

.partnerContent {
    width: 241px;
    font-weight: 600;
    color: blue;
    background: aliceblue;
    border-radius: 20px;
    text-align: center;
    padding: 5px;
    margin: 0 16px;
    img {
        vertical-align: bottom;
        margin-right: 4px;
    }
}

.coursePrice {
    @include font-size(18px);
    font-weight: 700;
}

.aboutWrapper {
    @include margin-bottom(20px);

    .btnWrapper {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        button {
            max-width: 280px;
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.downloadCourse {
    .heading {
        font-family: $opensans;
        color: $dark-gray;
        font-weight: 600;
        @include margin-bottom(20px);
    }
}

.dowloadList {
    >div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include margin-bottom(15px);
        @include padding-bottom(15px);
        border-bottom: 1px solid $xslight-gray;

        &:last-child {
            border-bottom: none;
        }

        a {
            font-family: $opensans;
            font-weight: 600;
        }

        .size {
            margin-left: auto;
            font-family: $opensans;
            font-weight: 600;
            color: $medium-gray;
            letter-spacing: -0.33px;
            @include font-size(12px);
        }

        svg {
            @include margin-right(16px);
        }
    }
}

.description {
    color: $xs-dark-gray;
    white-space: pre-wrap;
    @include font-size(14px);
    line-height: 1.4;
    @include margin-bottom(28px);
}

.courseInstructor {
    @include margin-bottom(28px);
    .instHeading {
        @include font-size(14px);
        font-weight: 500;
        line-height: 1;
        @include margin-bottom(28px);
    }
}

.intructorDetail {
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;

    .img {
        flex-basis: 70px;

        img {
            @include border-radius(50%);
            border: 1px solid $gray;
            width: 70px;
            height: 68px;
        }
    }

    .insDetailInner {
        flex-basis: calc(100% - 70px);
        @include padding-left(18px);

        .instName {
            @include font-size(14px);
            font-weight: 700;
            line-height: 1;
            color: $branding1;
            @include margin-bottom(18px);
        }

        .shortDes {
            @include font-size(12px);
            font-family: $opensans;
            color: $xs-dark-gray;
            letter-spacing: -0.33px;
            width: 100%;
        }
    }
}